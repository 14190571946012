import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image'
import {
  Grid,
  Typography,
  IconButton,
  MenuList,
  Button,
  Hidden
} from '@material-ui/core'
import {
  Instagram as InstagramIcon,
  Facebook as FacebookIcon,
  LinkedIn as LinkedInIcon
} from '@material-ui/icons'
import { usePopupState, bindTrigger } from 'material-ui-popup-state/hooks'
import {
  getLanguageLocalName,
  Locale
} from '../../constants/translationMethods'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase'
import {
  HOME_PATH,
  CONCERT_LIST_PATH,
  BACKSTAGE_PRICES_PATH,
  ABOUT_PATH,
  CONTACT_PATH,
  PRIVACY_POLICY_PATH,
  ENSEMBLE_LIST_PATH,
  VENUE_LIST_PATH,
  FESTIVAL_LIST_PATH,
  NEWS_PATH,
  CONCERTSERIES_LIST_PATH,
  SPONSORS_PATH,
  PRICES_PATH
} from '../../constants/paths'
import LanguageSelect from './LanguageSelect'
import { useTranslation } from 'next-i18next'
import { AppState } from '../../store/reducers'
import { useRegion } from '../../hooks/useRegion'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    position: 'absolute',
    zIndex: 300
  },
  container: {
    maxWidth: '1500px',
    padding: theme.spacing(3, 4, 0, 4),
    margin: '0 !important'
  },
  gridItem: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    margin: '0 !important'
  },
  logo: {
    maxWidth: '200px',
    width: '100%',
    maxHeight: '100px',
    objectFit: 'contain'
  },
  logoContainer: {
    paddingTop: '0 !important'
  },
  socialButton: {
    color: theme.palette.primary.main,
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  socialIcon: {
    fontSize: '1.5rem',
    '@media screen and (min-width: 600px)': {
      fontSize: '2.1875rem'
    }
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '1rem',
    textTransform: 'uppercase'
  },
  menuItem: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 0, 0.5, 0),
    display: 'block',
    fontSize: '1rem',
    lineHeight: 1.5
  },
  newMenuItemGroup: {
    marginTop: theme.spacing(3)
  },
  address: {
    padding: theme.spacing(1, 0),
    lineHeight: 1.5
  },
  disabled: {
    color: '#8187AD',
    '& > a': {
      color: '#8187AD'
    }
  },
  langButton: {
    fontSize: '1rem',
    width: '156px',
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914',
    textTransform: 'uppercase'
  },
  collapsedLangButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  expandedLangButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  '@media screen and (min-width: 600px)': {
    container: {
      padding: theme.spacing(6, 4, 0, 4)
    }
  },
  contactContainer: {
    '@media screen and (max-width: 600px)': {
      marginLeft: 16
    }
  }
}))

function Footer({ handleLoginOpen }) {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  // Get auth from redux state
  const auth = useSelector((state: AppState) => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)
  const router = useRouter()
  const region = useRegion()

  const handleLogin = (e) => {
    e.preventDefault()
    handleLoginOpen()
  }

  const handleScrollToTop = () => window.scrollTo(0, 0)

  const popupStateLang = usePopupState({
    variant: 'popover',
    popupId: 'langFooter'
  })

  return (
    <Grid container justifyContent='flex-start' className={classes.root}>
      <Grid container justifyContent='center' style={{ width: '98vw' }}>
        <Grid container item className={classes.container} spacing={6}>
          <Grid
            container
            item
            xs={12}
            sm={6}
            md={3}
            alignContent='flex-start'
            spacing={4}
            className={classes.gridItem}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent='flex-start'
              className={classes.logoContainer}
            >
              <Link href={HOME_PATH(region)}>
                <a>
                  <Image
                    src='/images/logo.png'
                    height={64}
                    width={160}
                    className={classes.logo}
                    alt='Live Klassisk'
                  />
                </a>
              </Link>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ marginLeft: 6, marginBottom: 12 }}
            >
              <Grid item>
                <IconButton
                  href='https://www.instagram.com/live_klassisk/'
                  target='_blank'
                  rel='noopener'
                  className={classes.socialButton}
                >
                  <InstagramIcon className={classes.socialIcon} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  href='https://www.facebook.com/liveklassisk/'
                  target='_blank'
                  rel='noopener'
                  className={classes.socialButton}
                >
                  <FacebookIcon className={classes.socialIcon} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  href='https://www.linkedin.com/company/live-klassisk/'
                  target='_blank'
                  rel='noopener'
                  className={classes.socialButton}
                >
                  <LinkedInIcon className={classes.socialIcon} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>

          <Hidden smDown>
            <Grid item sm={3} lg={2} className={classes.gridItem}>
              <Typography variant='h5' className={classes.heading}>
                {t('Navigation')}
              </Typography>
              <MenuList>
                <Link href={CONCERT_LIST_PATH(region)}>
                  <a onClick={handleScrollToTop} className={classes.menuItem}>
                    {t('Concerts')}
                  </a>
                </Link>
                <Link href={NEWS_PATH(region)}>
                  <a onClick={handleScrollToTop} className={classes.menuItem}>
                    {t('News')}
                  </a>
                </Link>
                <Link href={FESTIVAL_LIST_PATH(region)}>
                  <a onClick={handleScrollToTop} className={classes.menuItem}>
                    {t('Festivals')}
                  </a>
                </Link>
                <Link href={CONCERTSERIES_LIST_PATH(region)}>
                  <a onClick={handleScrollToTop} className={classes.menuItem}>
                    {t('Concert Series')}
                  </a>
                </Link>
                <Link href={ENSEMBLE_LIST_PATH(region)}>
                  <a onClick={handleScrollToTop} className={classes.menuItem}>
                    {t('Ensembles')}
                  </a>
                </Link>
                <Link href={VENUE_LIST_PATH(region)}>
                  <a onClick={handleScrollToTop} className={classes.menuItem}>
                    {t('Venues')}
                  </a>
                </Link>
              </MenuList>
            </Grid>

            <Grid item sm={3} lg={2} className={classes.gridItem}>
              <Typography variant='h5' className={classes.heading}>
                Live Klassisk
              </Typography>
              <MenuList>
                <Link href={ABOUT_PATH(region)}>
                  <a onClick={handleScrollToTop} className={classes.menuItem}>
                    {t('About us')}
                  </a>
                </Link>
                <Link href={CONTACT_PATH(region)}>
                  <a onClick={handleScrollToTop} className={classes.menuItem}>
                    {t('Contact')}
                  </a>
                </Link>

                <Link href={SPONSORS_PATH(region)}>
                  <a
                    onClick={handleScrollToTop}
                    className={`${classes.menuItem} ${classes.newMenuItemGroup}`}
                  >
                    {t('Partners & sponsors')}
                  </a>
                </Link>

                <Link href={PRICES_PATH(region)}>
                  <a onClick={handleScrollToTop} className={classes.menuItem}>
                    {t('Prices')}
                  </a>
                </Link>

                {!authExists ? (
                  <Link href='#'>
                    <a onClick={handleLogin} className={classes.menuItem}>
                      {t('Organizer login')}
                    </a>
                  </Link>
                ) : (
                  <div>
                    <div>
                      {/backstage/.test(router.pathname) ? (
                        <Link href={HOME_PATH(region)} passHref>
                          <a className={classes.menuItem}>
                            {t('Back to Live Klassisk')}
                          </a>
                        </Link>
                      ) : (
                        <Link href={BACKSTAGE_PRICES_PATH(region)} passHref>
                          <a className={classes.menuItem}>
                            {t('Go backstage')}
                          </a>
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </MenuList>
            </Grid>
          </Hidden>

          <Grid item sm={3} className={classes.gridItem}>
            <div className={classes.contactContainer}>
              <Typography variant='h5' className={classes.heading}>
                {t('Contact')}
              </Typography>
              <Typography className={classes.address}>
                {t('+45 2241 4168')} <br />
                {t('info@liveklassisk.dk')} <br /> <br />
                {t('Live Klassisk ApS')} <br />
                {t('CVR 41507780')}
              </Typography>
            </div>
          </Grid>
          <Hidden mdDown>
            <Grid
              container
              item
              md={2}
              justifyContent='flex-end'
              alignContent='flex-start'
              className={classes.gridItem}
            >
              <Button
                {...bindTrigger(popupStateLang)}
                className={`${classes.langButton} ${
                  popupStateLang.isOpen
                    ? classes.expandedLangButton
                    : classes.collapsedLangButton
                }`}
                disableRipple
              >
                {region.toUpperCase()} |{' '}
                {getLanguageLocalName(i18n.language as Locale)}
              </Button>
              <LanguageSelect id='langFooter' popupState={popupStateLang} />
            </Grid>
          </Hidden>
          <Grid container item justifyContent='center' xs={12}>
            <Typography variant='body1' className={classes.disabled}>
              Copyright © {new Date().getFullYear()} Live Klassisk •{' '}
              <Link href={PRIVACY_POLICY_PATH(region)}>
                {t('Privacy and cookie policy')}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Footer
