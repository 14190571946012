import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { AppBar, Toolbar, Grid, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Star as StarIcon } from '@material-ui/icons'
import { usePopupState, bindTrigger } from 'material-ui-popup-state/hooks'
import {
  getLanguageLocalName,
  Locale,
  localizedCountryName
} from '../../constants/translationMethods'
import LanguageSelect from './LanguageSelect'
import EnglishNewsHamburgerMenu from './EnglishNewsHamburgerMenu'
import { useTranslation } from 'next-i18next'
import { useRegion } from '../../hooks/useRegion'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: {
    height: '100px',
    padding: 0
  },
  active: {
    boxShadow: `0 4px 0 ${theme.palette.primary.main}`
  },
  disabled: {
    color: '#8187AD !important'
  },
  appBar: {
    padding: theme.spacing(0, 2),
    zIndex: 300
  },
  logoContainer: {
    width: '230px',
    maxWidth: '230px',
    marginRight: theme.spacing(2),
    padding: theme.spacing(2, 0, 1.5, 1.5)
  },
  title: {
    textTransform: 'uppercase',
    display: 'inline',
    fontWeight: 600,
    width: '100%',
    maxWidth: '700px',
    fontSize: '11px',
    color: '#081359',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  logo: {
    width: '100%',
    objectFit: 'contain',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  menuItems: {
    display: 'none',
    margin: theme.spacing(0, 0, 0.5, 0)
  },
  menuItem: {
    color: theme.palette.primary.main,
    '&:active': {
      color: theme.palette.primary.main
    },
    '&:visited': {
      color: theme.palette.primary.main
    },
    '&:focus': {
      outline: 'none'
    }
  },
  mainMenuItem: {
    textTransform: 'capitalize',
    fontSize: '24px',
    marginRight: theme.spacing(4),
    lineHeight: '1.5'
  },
  hamburgerMenuContainer: {
    minWidth: '50px',
    maxWidth: '50px'
  },
  langContainer: {
    padding: theme.spacing(0),
    zIndex: 1
  },
  langButton: {
    fontSize: '1rem',
    width: '156px',
    borderRadius: '0 0 8px 8px',
    boxShadow: '0px 3px 11px #08135914',
    textTransform: 'uppercase'
  },
  collapsedLangButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  expandedLangButton: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white'
  },
  elevation: {
    boxShadow: '0px 3px 11px #08135914'
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(3),
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914'
  },
  tooltipArrow: {
    color: theme.palette.primary.main
  },
  tooltipHeading: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  '@media screen and (min-width: 960px)': {
    menuItem: {
      marginRight: theme.spacing(6)
    },
    menuItems: {
      display: 'flex'
    }
  },
  '@media screen and (min-width: 600px)': {
    logoContainer: {
      padding: theme.spacing(2, 0, 1.5, 2)
    },
    appBar: {
      padding: theme.spacing(0, 3)
    }
  },
  starContainer: {
    position: 'absolute',
    right: 27,
    top: -2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  star: {
    position: 'absolute',
    color: '#F9C80E',
    fontSize: '2.2rem'
  },
  newText: {
    position: 'relative',
    zIndex: 1,
    fontSize: '0.7rem'
  }
}))

interface NavbarProps {
  handleLoginOpen(): void
}

const NavMenuItem = ({ name, url }) => {
  const classes = useStyles()

  const isActive = url === '/en/news'

  return (
    <Link href={url} passHref>
      <a
        className={`${classes.menuItem} ${classes.mainMenuItem} ${
          isActive ? classes.active : ''
        }`}
      >
        {name}
      </a>
    </Link>
  )
}

const EnglishNewsNavbar: React.FC<NavbarProps> = ({ handleLoginOpen }) => {
  const { t, i18n } = useTranslation()
  const classes = useStyles()
  const region = useRegion()

  const popupStateLang = usePopupState({
    variant: 'popover',
    popupId: 'lang'
  })

  return (
    <div className={classes.root}>
      <AppBar
        position='static'
        className={classes.appBar}
        color='transparent'
        elevation={0}
      >
        <Grid
          className={classes.langContainer}
          container
          justifyContent='flex-end'
        >
          <Button
            {...bindTrigger(popupStateLang)}
            className={`${classes.langButton} ${
              popupStateLang.isOpen
                ? classes.expandedLangButton
                : classes.collapsedLangButton
            }`}
            disableRipple
          >
            {region.toUpperCase()} |{' '}
            {getLanguageLocalName(i18n.language as Locale)}
          </Button>
        </Grid>
        <LanguageSelect id='lang' popupState={popupStateLang} />
        <Toolbar className={classes.toolbar}>
          <Grid
            container
            spacing={4}
            justifyContent='space-between'
            alignItems='flex-end'
          >
            <div className={classes.logoContainer}>
              <Link href='/' passHref>
                <a>
                  <Image
                    src='/images/logo.png'
                    height={64}
                    width={160}
                    className={classes.logo}
                    alt='LiveKlassisk logo'
                  />
                </a>
              </Link>
              <div>
                <Link href='/' passHref>
                  <Typography className={classes.title} component='h1'>
                    {t('Classical music in')} {t(localizedCountryName(region))}
                  </Typography>
                </Link>
              </div>
            </div>
            <Grid
              container
              item
              xs
              className={classes.menuItems}
              alignItems='flex-end'
            >
              <NavMenuItem name={t('Concerts')} url='/' />
              <span style={{ position: 'relative' }}>
                <NavMenuItem name={t('News')} url='/' />
                <div className={classes.starContainer}>
                  <StarIcon className={classes.star} />
                  <div className={classes.newText}>{t('New')}</div>
                </div>
              </span>
              <NavMenuItem name={t('Festivals')} url='/' />
              <NavMenuItem name={t('Concert Series')} url='/' />
              <NavMenuItem name={t('Ensembles')} url='/' />
              <NavMenuItem name={t('Venues')} url='/' />
            </Grid>
            <Grid
              container
              item
              xs
              className={classes.hamburgerMenuContainer}
              alignItems='flex-end'
              justifyContent='flex-end'
            >
              <EnglishNewsHamburgerMenu handleLoginOpen={handleLoginOpen} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default EnglishNewsNavbar
