import { createStore } from 'redux'
import rootReducer from './reducers'

const store = () => {
  const store = createStore(rootReducer)

  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers.
    ;(module as any).hot.accept('./reducers', () => {
      const nextRootReducer = require('./reducers')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}

export default store
