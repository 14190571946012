import { SearchParams } from 'meilisearch'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { meiliSearchPublicClient } from '../utils/meilisearch'

export default function useSearch<T = any>(
  // If filter is empty we assume a search without any filters, if filters is undefined we return an empty search object.
  filter: string,
  indexName: string,
  query: string,
  numHits: number = 20
) {
  const index = useMemo(
    () => meiliSearchPublicClient.index(indexName),
    [indexName]
  )

  const limit = numHits ?? 20
  const [hits, setHits] = useState<(T & { objectID: string })[]>([])
  const [offset, setOffset] = useState(0)
  const [hitsLastSearch, setHitsLastSearch] = useState(limit)
  const [shouldLoadNextPage, setShouldLoadNextPage] = useState(false)
  const [loading, setLoading] = useState(false)

  const hasMore = !shouldLoadNextPage && hitsLastSearch >= limit

  const resetSearchResults = useCallback(() => {
    // The following line would clear previous results while loading.
    setHits([])
    setOffset(0)
    setHitsLastSearch(limit)
    setShouldLoadNextPage(true)
  }, [limit])

  const handleRefresh = () => {
    // console.log('Will clear results and refresh')
    resetSearchResults()
  }

  useEffect(() => {
    // console.log('Will clear results and schedule new search')
    resetSearchResults()
  }, [filter, query, resetSearchResults])

  useEffect(() => {
    let ignore = false // Handle out-of-order responses.

    if (!shouldLoadNextPage) return
    async function performSearch() {
      setLoading(true)
      const options: SearchParams = { limit, offset }

      if (filter && filter !== '') {
        options.filter = filter
      }

      const { hits } = await index.search<T & { objectID: string }>(
        query === undefined || query === '' ? null : query,
        options
      )
      // console.log(`Found ${hits.length} hits`)
      if (!ignore && hits) {
        // console.log(`Will commit hits. ${page}, ${nbPages}`)
        setShouldLoadNextPage(false)
        setHits((currentHits) =>
          offset === 0 ? hits : [...currentHits, ...hits]
        )
        setOffset(offset + limit)
        setHitsLastSearch(hits.length)
      }
      setLoading(false)
    }
    // console.log('Will initiate new search')
    performSearch()
    return () => {
      ignore = true
    }
  }, [shouldLoadNextPage, offset, filter, index, query, limit])

  const loadMore = () => setShouldLoadNextPage(true)

  return {
    hits: filter === undefined ? [] : hits,
    hasMore: filter === undefined ? false : hasMore,
    loadMore: loadMore,
    handleRefresh: handleRefresh,
    loading
  }
}
