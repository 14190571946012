import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import LoginDialog from '../LoginDialog'
import CookieBanner from '../CookieBanner'
import { useRouter } from 'next/router'
import BackstageNavbar from './BackstageNavbar'
import EnglishNewsFooter from './EnglishNewsFooter'
import FooterAd from '../ads/FooterAd'
import EnglishNewsNavbar from './EnglishNewsNavbar'
import { useSelector } from 'react-redux'

const Layout: React.FC = ({ children }) => {
  const router = useRouter()
  const auth = useSelector((state: any) => state.firebase.auth)

  const [loginOpen, setLoginOpen] = useState(false)

  const handleLoginOpen = () => setLoginOpen(true)
  const handleLoginClose = () => setLoginOpen(false)

  useEffect(() => {
    if (router.query.login === 'true' && auth.isLoaded && auth.isEmpty) {
      // Use is not logged in
      handleLoginOpen()
    }
  }, [router.query.login, auth.isLoaded])

  if (router.pathname === '/' || router.pathname === '/app') {
    return <main>{children}</main>
  }

  if (/\/en\//.test(router.asPath)) {
    return (
      <>
        <EnglishNewsNavbar handleLoginOpen={handleLoginOpen} />
        <main>{children}</main>
        <EnglishNewsFooter handleLoginOpen={handleLoginOpen} />
      </>
    )
  }

  return (
    <>
      {/backstage/.test(router.pathname) ? (
        <BackstageNavbar />
      ) : (
        <Navbar handleLoginOpen={handleLoginOpen} />
      )}
      <main>
        {children}
        {!/backstage/.test(router.pathname) && <FooterAd />}
      </main>
      <Footer handleLoginOpen={handleLoginOpen} />
      <CookieBanner />
      <LoginDialog open={loginOpen} handleClose={handleLoginClose} />
    </>
  )
}

export default Layout
