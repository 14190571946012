import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useFirebase } from 'react-redux-firebase'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Grid,
  InputLabel,
  TextField,
  CircularProgress,
  Theme
} from '@material-ui/core'
import { PRIVACY_POLICY_PATH } from '../constants/paths'
import { useTranslation } from 'next-i18next'
import axios from 'axios'
import throttle from 'lodash/throttle'
import getEnv from '../utils/getEnv'
import { useRegion } from '../hooks/useRegion'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing(3.5),
    color: theme.palette.primary.main,
    '& .MuiTypography-h2': {
      fontWeight: 600,
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8187AD',
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F5F6F8',
      '&.Mui-disabled': {
        backgroundColor: '#FFF'
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    },
    '& .MuiInputBase-root': {
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiOutlinedInput-input': {
      color: theme.palette.primary.main
    },
    '& .MuiAutocomplete-root': {
      color: theme.palette.primary.main
    }
  },
  form: {
    marginTop: theme.spacing(2)
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    textAlign: 'center'
  },
  text: {
    color: theme.palette.primary.main,
    fontSize: '14px',
    '& > a': {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.main
      }
    }
  },
  inputContainer: {
    marginTop: theme.spacing(-3)
  },
  inputLabel: {
    paddingLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.primary.main,
    textAlign: 'left'
  },
  input: {
    backgroundColor: '#F5F6F8',
    color: theme.palette.primary.main,
    '&::placeholder': {
      color: '#8187AD'
    }
  },
  inputOutline: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  inputBase: {
    padding: theme.spacing(1.5, 1.5)
  },
  singleLineInput: {
    height: '40px'
  },
  multiline: {
    padding: theme.spacing(0)
  },
  submitButton: {
    maxWidth: '250px',
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914',
    padding: theme.spacing(1, 6, 1, 6),
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover, &:active, &:hover:disabled, &:disabled': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    right: '0',
    marginTop: -12,
    marginRight: 12
  },
  errorMessage: {
    fontSize: '1rem',
    height: '40px',
    width: '100%',
    textAlign: 'center',
    color: 'red',
    marginBottom: theme.spacing(2)
  },
  notchedOutline: {
    border: '1px solid #081359'
  },
  '@media screen and (min-width: 768px)': {
    root: {
      padding: theme.spacing(1.5)
    }
  }
}))

const errorMsg = (code, t) => {
  switch (code) {
    case undefined:
      return undefined
    case 'auth/weak-password':
      return t('Password should be at least 6 characters')
    case 'auth/email-already-in-use':
      return t('The email address is already in use by another account.')
    case 'auth/username-already-in-use':
      return t('The email address is already in use by another account.')
    default:
      return t('An error occured.')
  }
}

const url =
  getEnv() === 'production'
    ? 'https://europe-west1-liveklassisk.cloudfunctions.net/usernameCheck'
    : 'https://europe-west1-liveklassisk-staging.cloudfunctions.net/usernameCheck'

const SignupForm = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const firebase = useFirebase()
  const region = useRegion()

  const [error, setError] = useState(undefined)
  const errorMessage = errorMsg(error, t)

  const checkUsername = async (value?: string): Promise<boolean> => {
    if (!value) return false
    try {
      const res = await axios.post(url, {
        data: { username: value.toLowerCase() }
      })
      return res.data.result.size === 0
    } catch (error) {
      console.error(error)
      throw new Error('Failed to connect with API')
    }
  }

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    const profile = {
      email: values.email,
      username: values.username,
      status: 'pending',
      isSuperAdmin: false,
      isVip: false,
      firstName: values.firstName,
      lastName: values.lastName,
      telephone: values.telephone,
      company: values.company,
      description: values.description,
      countryCode: region.toUpperCase(),
      isDeleted: false,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    }
    firebase.createUser(values, profile).catch((err) => {
      console.log(err)
      setSubmitting(false)
      setError(err.code)
    })
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        username: '',
        telephone: '',
        email: '',
        password: '',
        company: '',
        description: ''
      }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required(t('Please enter your first name.')),
        lastName: Yup.string().required(t('Please enter your last name.')),
        username: Yup.string()
          .required(t('Please enter a username.'))
          .matches(
            /^(?=[a-zA-Z0-9._]{1,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/,
            t(
              'Your username may only consist of letters, underscore, dots and be 1 - 20 characters long'
            )
          )
          .test(
            'checkDuplicateUsername',
            t('This username already exists'),
            throttle(checkUsername, 300)
          ),
        telephone: Yup.string().required(t('Please enter your phone number.')),
        email: Yup.string()
          .email(t('Please enter a valid email'))
          .required(t('Please enter your email')),
        password: Yup.string().required(t('Please enter your password')),
        company: Yup.string().required(
          t(
            'Please enter the company/musician/festival/institution you represent'
          )
        ),
        description: Yup.string().required(
          t(
            'Please write a bit about yourself and your role as a concert organizer'
          )
        )
      })}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2} direction='row' alignItems='flex-start'>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.inputLabel}>
                {t('First name')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t('Enter your first name')}
                name='firstName'
                error={!!(errors.firstName && touched.firstName)}
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.firstName && touched.firstName && errors.firstName
                }
                InputProps={{
                  className: classes.input,
                  classes: {
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.inputLabel}>
                {t('Last name')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t('Enter your last name')}
                name='lastName'
                error={!!(errors.lastName && touched.lastName)}
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.lastName && touched.lastName && errors.lastName
                }
                InputProps={{
                  className: classes.input,
                  classes: {
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.inputLabel}>
                {t('Phone number')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t('Enter your phone number')}
                name='telephone'
                error={!!(errors.telephone && touched.telephone)}
                value={values.telephone}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.telephone && touched.telephone && errors.telephone
                }
                InputProps={{
                  className: classes.input,
                  classes: {
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.inputLabel}>
                {t('E-mail address')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t('Enter your e-mail')}
                name='email'
                autoComplete='email'
                error={!!(errors.email && touched.email)}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.email && touched.email && errors.email}
                InputProps={{
                  className: classes.input,
                  classes: {
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.inputLabel}>
                {t('Username')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t('Choose a username')}
                type='text'
                name='username'
                autoComplete='username'
                error={!!(errors.username && touched.username)}
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.username && touched.username && errors.username
                }
                InputProps={{
                  className: classes.input,
                  classes: {
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel className={classes.inputLabel}>
                {t('Password')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t('Choose a password')}
                type='password'
                name='password'
                autoComplete='new-password'
                error={!!(errors.password && touched.password)}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.password && touched.password && errors.password
                }
                InputProps={{
                  className: classes.input,
                  classes: {
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputLabel className={classes.inputLabel}>
                {t('Representation')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t('Please input whom it is you represent')}
                type='text'
                name='company'
                error={!!(errors.company && touched.company)}
                value={values.company}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.company && touched.company && errors.company}
                InputProps={{
                  className: classes.input,
                  classes: {
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('Description')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t(
                  'Write a bit about yourself and your role as a concert organizer'
                )}
                name='description'
                multiline
                rows='4'
                error={!!(errors.description && touched.description)}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.description &&
                  touched.description &&
                  errors.description
                }
                InputProps={{
                  classes: {
                    root: `${classes.input} ${classes.multiline}`,
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} container justifyContent='center'>
              <p className={classes.text}>
                {t('When submitting you simultaneously consent to our')}{' '}
                <a
                  href={PRIVACY_POLICY_PATH(region)}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <b>{t('privacy policy')}</b>
                </a>
              </p>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.submit}>
                <Button
                  type='submit'
                  fullWidth
                  className={classes.submitButton}
                  disabled={isSubmitting}
                >
                  {t('Sign up')}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.errorMessage}>
              {errorMessage}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default SignupForm
