import React from 'react'
import { Typography, Button, Paper, Popover, Divider } from '@material-ui/core'
import { CheckCircle as CheckIcon } from '@material-ui/icons'
import {
  LanguageSelectProps,
  Locale,
  localeToLanguageKey,
  regionToLocale
} from '../../constants/translationMethods'
import { bindPopover } from 'material-ui-popup-state/hooks'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'next-i18next'
import { useRegion } from '../../hooks/useRegion'
import { useRouter } from 'next/router'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  langMenu: {
    color: 'white',
    lineHeight: 1.5,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    width: '260px',
    padding: theme.spacing(3, 3, 3, 3)
  },
  popoverLang: {
    marginTop: theme.spacing(2)
  },
  popoverLangFooter: {
    marginTop: theme.spacing(-2)
  },
  langMenuItem: {
    backgroundColor: 'white',
    display: 'flex',
    padding: theme.spacing(0.5, 1, 0.5, 6),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    borderRadius: '4px',
    width: '100%',
    justifyContent: 'space-between',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  langMenuHeading: {
    fontSize: '1rem',
    fontWeight: 'bold'
  },
  langMenuParagraph: {
    marginTop: theme.spacing(2)
  },
  newMenuItemGroup: {
    marginTop: theme.spacing(2.5)
  },
  elevation: {
    boxShadow: '0px 3px 11px #08135914'
  }
}))

type LocalLanguageButtonProps = {
  locale: Locale
}

const LanguageButton: React.VFC<LocalLanguageButtonProps> = ({ locale }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()

  const currentLocale = i18n.language

  return (
    <Button
      className={classes.langMenuItem}
      endIcon={currentLocale === locale && <CheckIcon />}
      disableRipple
      onClick={() => i18n.changeLanguage(locale)}
    >
      {t(localeToLanguageKey(locale))}
    </Button>
  )
}

const LanguageSelect: React.FC<LanguageSelectProps> = (props) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const region = useRegion()
  const defaultLocale = regionToLocale(region)

  return (
    <Popover
      {...bindPopover(props.popupState)}
      anchorOrigin={{
        vertical: props.id === 'langFooter' ? 'top' : 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: props.id === 'langFooter' ? 'bottom' : 'top',
        horizontal: 'right'
      }}
      elevation={0}
      className={
        props.id === 'langFooter'
          ? classes.popoverLangFooter
          : classes.popoverLang
      }
      PaperProps={{
        className: classes.elevation
      }}
    >
      <Paper id={props.id} elevation={0} className={classes.langMenu}>
        <Typography className={classes.langMenuHeading} variant='h5'>
          {t('Language')}
        </Typography>

        <div className={classes.newMenuItemGroup}>
          <LanguageButton locale={defaultLocale} />
          <LanguageButton locale='en' />
        </div>

        <Typography className={classes.langMenuParagraph} variant='body1'>
          {t('Note that language does not affect concert descriptions')}.
        </Typography>
      </Paper>
    </Popover>
  )
}

export default LanguageSelect
