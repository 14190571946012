export const HOME_PATH = (region: string) => `/${region}`

export const CONCERT_LIST_PATH = (region: string) => `/${region}/concerts`
export const CONCERT_DETAILS_PATH = (region: string, slug: string) =>
  `/${region}/concerts/${slug}`

export const FESTIVAL_LIST_PATH = (region: string) => `/${region}/festivals`
export const FESTIVAL_DETAILS_PATH = (region: string, slug: string) =>
  `${FESTIVAL_LIST_PATH(region)}/${slug}`

export const ENSEMBLE_LIST_PATH = (region: string) => `/${region}/ensembles`
export const ENSEMBLE_DETAILS_PATH = (region: string, slug: string) =>
  `${ENSEMBLE_LIST_PATH(region)}/${slug}`

export const VENUE_LIST_PATH = (region: string) => `/${region}/venues`
export const VENUE_DETAILS_PATH = (region: string, slug: string) =>
  `${VENUE_LIST_PATH(region)}/${slug}`

export const CONCERTSERIES_LIST_PATH = (region: string) =>
  `/${region}/concertseries`
export const CONCERTSERIES_DETAILS_PATH = (region: string, slug: string) =>
  `${CONCERTSERIES_LIST_PATH(region)}/${slug}`

export const UNAUTHORIZED_PATH = (region: string) => `/${region}/unauthorized`
export const ABOUT_PATH = (region: string) => `/${region}/about`
export const CONTACT_PATH = (region: string) => `/${region}/contact`
export const PRIVACY_POLICY_PATH = (region: string) =>
  `/${region}/privacy-policy`

export const NEWS_PATH = (region: string) => `/${region}/news`
export const NEWS_POST_PATH = (region: string, slug: string) =>
  `${NEWS_PATH(region)}/${slug}`

export const PENDINGACTIVATION_PATH = (region: string) => `/${region}/pending`
export const REJECTED_PATH = (region: string) => `/${region}/rejected`

export const BACKSTAGE_PRICES_PATH = (region: string) =>
  `/${region}/backstage/prices`
export const BACKSTAGE_CONCERT_LIST_PATH = (region: string) =>
  `/${region}/backstage/concerts`
export const BACKSTAGE_USER_LIST_PATH = (region: string) =>
  `/${region}/backstage/users`
export const BACKSTAGE_ENSEMBLE_LIST_PATH = (region: string) =>
  `/${region}/backstage/ensembles`
export const BACKSTAGE_FESTIVAL_LIST_PATH = (region: string) =>
  `/${region}/backstage/festivals`
export const BACKSTAGE_CONCERTSERIES_LIST_PATH = (region: string) =>
  `/${region}/backstage/concertseries`
export const BACKSTAGE_VENUE_LIST_PATH = (region: string) =>
  `/${region}/backstage/venues`
export const ACCOUNT_PATH = (region: string) => `/${region}/backstage/account`
export const BACKSTAGE_NEWS_LIST_PATH = (region: string) =>
  `/${region}/backstage/news`
export const BACKSTAGE_ADGROUPS_LIST_PATH = (region: string) =>
  `/${region}/backstage/ads`
export const BACKSTAGE_ADGROUP_PATH = (region: string, id: string) =>
  `/${region}/backstage/ad-groups/${id}`
export const BACKSTAGE_ADGROUP_EDIT_PATH = (region: string, id: string) =>
  `/${region}/backstage/ad-groups/${id}/edit`
export const BACKSTAGE_AD_PATH = (
  region: string,
  id: string,
  adGroupId?: string
) => {
  let url = `/${region}/backstage/ads/${id}`

  if (adGroupId) {
    url += `?adGroupId=${adGroupId}`
  }

  return url
}

export const PRICES_PATH = (region: string) => `/${region}/prices`
export const SPONSORS_PATH = (region: string) => `/${region}/sponsors`
