import { useEffect, useState } from 'react'
import { ExtendedFirestoreInstance, useFirestore } from 'react-redux-firebase'
import { useRegion } from '../../hooks/useRegion'
import useSearch from '../../hooks/useSearch'
import { Ad, AdFormat } from '../../types/types'
import { getRandom32bitNumber } from '../random'

export const useAdSearch = (filters: string, query: string, numHits?: number) =>
  useSearch<Ad>(filters, 'ads', query, numHits)

export const generateAdId = (firestore: ExtendedFirestoreInstance): string =>
  firestore.collection('ads').doc().id

export const createAd = async (
  firestore: ExtendedFirestoreInstance,
  data: Omit<Ad, 'createdAt' | 'updatedAt' | 'isDeleted'>,
  adId: string
): Promise<void> => {
  const order = getRandom32bitNumber()

  await firestore
    .collection('ads')
    .doc(adId)
    .set({
      ...data,
      order,
      isDeleted: false,
      clickCount: 0,
      viewCount: 0,
      createdAt: firestore.FieldValue.serverTimestamp(),
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const getAdDoc = async (
  firestore: ExtendedFirestoreInstance,
  adId: string
): Promise<firebase.firestore.DocumentSnapshot<Ad>> => {
  const adDoc = await firestore.collection('ads').doc(adId).get()
  return adDoc as firebase.firestore.DocumentSnapshot<Ad>
}

export const getRandomAd = async (
  firestore: ExtendedFirestoreInstance,
  format: AdFormat,
  countryCode: string
): Promise<Ad | null> => {
  const order = getRandom32bitNumber()

  const baseQuery = firestore
    .collection('ads')
    .where('format', '==', format)
    .where('countryCode', '==', countryCode)
    .where('isPublished', '==', true)
    .where('isDeleted', '==', false)

  const adData = await baseQuery
    .where('order', '>', order)
    .orderBy('order', 'asc')
    .limit(1)
    .get()

  const doc = adData.docs[0]

  if (doc) {
    return ({ ...doc.data(), id: doc.id } as Ad) ?? null
  }

  // No ad found - loop around and get the first ad
  if (adData.docs.length === 0) {
    const adData = await baseQuery
      .where('order', '>', 0)
      .orderBy('order', 'asc')
      .limit(1)
      .get()

    const doc = adData.docs[0]

    return doc ? ({ ...doc.data(), id: doc.id } as Ad) : null
  }
}

export const useRandomAd = (format: AdFormat): Ad | null => {
  const [ad, setAd] = useState<Ad | null>(null)
  const firestore = useFirestore()
  const region = useRegion()

  useEffect(() => {
    const getAd = async () => {
      const ad = await getRandomAd(firestore, format, region.toUpperCase())
      setAd(ad)
    }

    getAd()
  }, [firestore, format, region])

  return ad
}

export const updateAd = async (
  firestore: ExtendedFirestoreInstance,
  adId: string,
  data: Partial<Ad>
): Promise<void> => {
  await firestore
    .collection('ads')
    .doc(adId)
    .update({
      ...data,
      isDeleted: false,
      updatedAt: firestore.FieldValue.serverTimestamp()
    })
}

export const incremenetAdViewCount = async (
  firestore: ExtendedFirestoreInstance,
  adId: string
): Promise<void> => {
  await firestore
    .collection('ads')
    .doc(adId)
    .update({
      viewCount: firestore.FieldValue.increment(1)
    })
}

export const incremenetAdClickCount = async (
  firestore: ExtendedFirestoreInstance,
  adId: string
): Promise<void> => {
  await firestore
    .collection('ads')
    .doc(adId)
    .update({
      clickCount: firestore.FieldValue.increment(1)
    })
}

export const deleteAd = async (
  firestore: ExtendedFirestoreInstance,
  adId: string
): Promise<void> => {
  await firestore.collection('ads').doc(adId).update({
    isDeleted: true,
    udpatedAt: firestore.FieldValue.serverTimestamp()
  })
}

export const getAllPublicAds = async (
  firestore: ExtendedFirestoreInstance
): Promise<Ad[]> => {
  const adData = await firestore
    .collection('ads')
    .where('isPublished', '==', true)
    .get()

  return (adData.docs as unknown as Ad[]).filter((ad) => ad.isDeleted === false)
}

export const getAdsByAdGroup = async (
  firestore: ExtendedFirestoreInstance,
  adGroupId: string
): Promise<Ad[]> => {
  const adData = await firestore
    .collection('ads')
    .where('adGroup', '==', adGroupId)
    .get()

  return (adData.docs as unknown as Ad[]).filter((ad) => ad.isDeleted === false)
}
