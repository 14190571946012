import { createTheme } from '@material-ui/core/styles'

const theme = {
  palette: {
    background: {
      default: '#f5f6f8'
    },
    primary: {
      main: '#081359'
    },
    secondary: {
      main: '#E3E5ED'
    }
  },
  // Enable typography v2: https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    fontFamily: ['Futura', 'Arial'].join(',')
  },
  drawerWidth: 300,
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  flexRowCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['Futura', 'Futura'],
        body: {
          background: `url(/images/bg.png) repeat-y center 0`
        }
      }
    },
    // Removes extra margins for Concert Cards, when expanded
    MuiExpansionPanelSummary: {
      root: {
        '&:hover:not(.Mui-disabled)': {
          cursor: 'default'
        }
      },
      content: {
        '&.Mui-expanded': {
          margin: '12px 0'
        }
      }
    },
    // Removes hover colors from all buttons
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#081359'
        }
      },
      containedSecondary: {
        '&:hover': {
          backgroundColor: '#E3E5ED'
        }
      }
    }
  }
}

export default createTheme(theme as any)
