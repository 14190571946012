/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'local'

// Config for firebase
export const firebase = {
  apiKey: 'AIzaSyB1MloPHeTtY1HtpQNRugROEbShi69w43g',
  authDomain: 'liveklassisk.firebaseapp.com',
  databaseURL: 'https://liveklassisk.firebaseio.com',
  projectId: 'liveklassisk',
  storageBucket: 'liveklassisk.appspot.com',
  messagingSenderId: '415520250309',
  appId: '1:415520250309:web:177ac17aba5156694ad3ac',
  measurementId: 'G-F29848SPLM'
}

export const firebaseStagingConfig = {
  apiKey: 'AIzaSyC3_MuaAuViQYbgaq8RmfFf8hgTAiXYkM8',
  authDomain: 'liveklassisk-staging.firebaseapp.com',
  databaseURL: 'https://liveklassisk-staging.firebaseio.com',
  projectId: 'liveklassisk-staging',
  storageBucket: 'liveklassisk-staging.appspot.com',
  messagingSenderId: '268288875091',
  appId: '1:268288875091:web:867040664cbc5b377e04a6',
  measurementId: 'G-ZGP1LFELVJ'
}

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  userProfile: 'users', // root that user profiles are written to
  useFirestoreForProfile: true, // Save profile to Firestore instead of Real Time Database
  useFirestoreForStorageMeta: true // Metadata associated with storage file uploads goes to Firestore
}

const config = {
  env,
  firebase,
  reduxFirebase
}

export default config
