// Der må kun ændres ting på højre side af =
// Visible skal sættes til enten true eller false uden ''

// Dansk popup data
// Titlen på popup dialogenhttps://github.com/Kvalifik/liveklassisk/blob/master/src/constants/popupData.js
const DATitle = 'OPDATERING'
// Teksten på popup dialogen
const DABody =
  'Live Klassisk gennemgår en større opdatering og der kan forekomme mindre fejl og mangle enkelte koncerter på siden i disse dage.\n\nKontakt os endelig hvis I oplever større problemer.\n\nVi glæder os til at alle snart kan få glæde af Live Klassisk v. 2.0!'
// Om dialogen er synlig
const DAVisible = false
// Tekst på knappen
const DAButtonText = 'OK'

// Svensk popup data
const SETitle = 'VÄLKOMMEN TILL LIVE KLASSISK'
const SEBody =
  'Live Klassisk är främst verksamt i Danmark och Norge i dag, men kommer snart til Sverige. \n\nÄr du en arrangör? Du är varmt välkommen att skapa en användare och ladda upp dina konserter redan nu!!\n\nPublik? Registrera dig för vårt nyhetsbrev och få de senaste nyheterna när Live Klassisk verkligen drar igång i Sverige! Under tiden är du välkommen att använda de danska och norska sidorna på www.liveklassisk.dk och www.liveklassisk.no, där det finns tusentals klassiska konserter!'
const SEVisisble = true
const SEButtonText = 'OK'

// Norsk popup data
const NOTitle = 'KONSERTER OG KORONASITUASJONEN'
const NOBody =
  'På grunn av koronasituasjonen og ulike smitteverntiltak er mange konserter avlyste.\n\nKonsertene oppdateres fortløpende her på Live Klassisk, men vennligst sjekk med den enkelte arrangør for oppdatert informasjon. \n\nI mellomtiden streames en rekke konserter, og disse kan selvfølgelig også finnes her på Live Klassisk. Trykk på "Billetter" for å gå til den aktuelle stream.'
const NOVisisble = false
const NOButtonText = 'OK'

// Dette må ikke røres
export const DAData = {
  body: DABody,
  title: DATitle,
  visible: DAVisible,
  buttonText: DAButtonText
}
export const SEData = {
  body: SEBody,
  title: SETitle,
  visible: SEVisisble,
  buttonText: SEButtonText
}
export const NOData = {
  body: NOBody,
  title: NOTitle,
  visible: NOVisisble,
  buttonText: NOButtonText
}
