import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Divider,
  Grid,
  IconButton,
  Theme
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import LoginForm from '../components/LoginForm'
import SignupForm from '../components/SignupForm'
import ForgotForm from '../components/ForgotForm'
import { BACKSTAGE_PRICES_PATH, PRICES_PATH } from '../constants/paths'
import { useTranslation } from 'next-i18next'
import { AppState } from '../store/reducers'
import { useRegion } from '../hooks/useRegion'
import Link from 'next/link'

const AuthListener = ({ close }) => {
  const router = useRouter()
  const region = useRegion()

  const isAuthLoaded = useSelector(
    (state: AppState) => state.firebase.auth.isLoaded
  )
  const isAuthEmpty = useSelector(
    (state: AppState) => state.firebase.auth.isEmpty
  )

  useEffect(() => {
    if (isAuthLoaded && !isAuthEmpty) {
      router.push(BACKSTAGE_PRICES_PATH(region))
      close()
    }
  }, [isAuthLoaded, isAuthEmpty, router, close, region])

  return null
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: '#081359',
    borderRadius: '8px',
    boxShadow: '0 3px 11px #08135914',
    '& .MuiInputBase-root': {
      color: '#081359'
    },
    '& .MuiTypography-h2': {
      fontWeight: 600,
      color: '#081359'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8187AD',
      color: '#081359'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F5F6F8',
      '&.Mui-disabled': {
        backgroundColor: '#FFF'
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#081359',
      color: '#081359'
    }
  },
  heading: {
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: theme.spacing(-2)
  },
  heading2: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: theme.spacing(6)
  },
  p: {
    textAlign: 'center',
    margin: theme.spacing(3, 5)
  },
  button: {
    maxWidth: '250px',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
    padding: theme.spacing(1, 0),
    marginBottom: theme.spacing(5),
    borderRadius: '8px',
    boxShadow: '0 3px 11px #08135914',
    fontWeight: 'bold'
  },
  panel: {
    maxWidth: '600px',
    width: '100%',
    color: '#081359',
    padding: theme.spacing(1),
    borderRadius: '8px'
  },
  forgotButton: {
    textTransform: 'none',
    marginBottom: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(0, 6)
  }
}))

const LoginDialog = ({ open, handleClose }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const region = useRegion()

  const close = () => {
    handleClose()
    setCurrentView('login')
  }

  const [currentView, setCurrentView] = useState('login')

  const LoginTitle = () => (
    <>
      <Grid container item xs={12} justifyContent='flex-end'>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>

      <div>
        <Typography variant='h5' className={classes.heading}>
          {t('Log in')}
        </Typography>
      </div>
    </>
  )

  const Login = () => (
    <Grid container>
      <Grid item xs={12} container justifyContent='center'>
        <LoginForm />
      </Grid>

      <Grid item xs={12} container justifyContent='center'>
        <Button
          onClick={(e) => {
            e.preventDefault()
            setCurrentView('forgot')
          }}
          className={classes.forgotButton}
        >
          {t('Forgot your password')}?
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h5' className={classes.heading2}>
          {t('Become a concert organizer')}
        </Typography>
        <Typography variant='body1' className={classes.p}>
          {t(
            'To become an organizer and be able to create concerts etc., you must be approved by Live Klassisk'
          )}
          .
        </Typography>
        <Typography variant='body1' className={classes.p}>
          {t('Learn more about our prices')}{' '}
          <Link href={PRICES_PATH(region)}>
            <span
              onClick={() => close()}
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {t('here')}
            </span>
          </Link>
          .
        </Typography>
      </Grid>

      <Grid item container justifyContent='center'>
        <Button
          onClick={() => setCurrentView('signup')}
          className={classes.button}
          disableRipple
        >
          {t('Sign up as organizer')}
        </Button>
      </Grid>
    </Grid>
  )

  const SignupTitle = () => (
    <>
      <Grid container item xs={12} justifyContent='flex-end'>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>

      <div>
        <Typography variant='h5' className={classes.heading}>
          {t('Become a concert organizer')}
        </Typography>
      </div>
    </>
  )

  const Signup = () => (
    <Grid container>
      <Grid item xs={12}>
        <SignupForm />
      </Grid>

      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h5' className={classes.heading2}>
          {t('Already a verified organizer')}?
        </Typography>
        <Typography variant='body1' className={classes.p}>
          {t('To manage and create concerts, you must log in')}.
        </Typography>
      </Grid>

      <Grid item container justifyContent='center'>
        <Button
          onClick={() => setCurrentView('login')}
          className={classes.button}
          disableRipple
        >
          {t('Log in')}
        </Button>
      </Grid>
    </Grid>
  )

  const ForgotTitle = () => (
    <>
      <Grid container item xs={12} justifyContent='flex-end'>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Grid>

      <div>
        <Typography variant='h5' className={classes.heading}>
          {t('Forgot your password')}?
        </Typography>
      </div>
    </>
  )

  const Forgot = () => (
    <Grid container justifyContent='center'>
      <Grid item xs={12}>
        <Typography variant='body1' className={classes.p}>
          {t(
            'Enter your e-mail address below and we will send you an e-mail with a link to reset your password'
          )}
          .
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ForgotForm />
      </Grid>

      <Button
        onClick={(e) => {
          e.preventDefault()
          setCurrentView('login')
        }}
        className={classes.forgotButton}
      >
        {t('Go back to log in')}
      </Button>
    </Grid>
  )

  const View = (view) => {
    switch (view) {
      case 'signup':
        return <Signup />
      case 'forgot':
        return <Forgot />
      default:
        return <Login />
    }
  }

  const Title = (view) => {
    switch (view) {
      case 'signup':
        return <SignupTitle />
      case 'forgot':
        return <ForgotTitle />
      default:
        return <LoginTitle />
    }
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        classes={{ paperScrollPaper: classes.root }}
      >
        <DialogTitle>{Title(currentView)}</DialogTitle>
        <DialogContent>{View(currentView)}</DialogContent>
      </Dialog>
      {open && <AuthListener close={close} />}
    </>
  )
}

export default LoginDialog
