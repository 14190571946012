import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Image from 'next/image'
import { Grid, Typography, IconButton, MenuList, Box } from '@material-ui/core'
import {
  Instagram as InstagramIcon,
  Facebook as FacebookIcon
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty, useFirebase } from 'react-redux-firebase'
import { HOME_PATH, PRIVACY_POLICY_PATH } from '../../constants/paths'
import { useTranslation } from 'next-i18next'
import { AppState } from '../../store/reducers'
import { useRegion } from '../../hooks/useRegion'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    position: 'absolute',
    zIndex: 300
  },
  container: {
    maxWidth: '1500px',
    padding: theme.spacing(3, 4, 0, 4),
    margin: '0 !important'
  },
  gridItem: {
    paddingTop: '0 !important',
    paddingBottom: '0 !important',
    margin: '0 !important'
  },
  logo: {
    maxWidth: '200px',
    width: '100%',
    maxHeight: '100px',
    objectFit: 'contain'
  },
  logoContainer: {
    paddingTop: '0 !important'
  },
  social: {
    color: theme.palette.primary.main,
    padding: 0,
    margin: theme.spacing(2),
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '1rem',
    textTransform: 'uppercase'
  },
  menuItem: {
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 0, 0.5, 0),
    display: 'block',
    fontSize: '1rem',
    lineHeight: 1.5
  },
  newMenuItemGroup: {
    marginTop: theme.spacing(3)
  },
  address: {
    padding: theme.spacing(1, 0),
    lineHeight: 1.5
  },
  disabled: {
    color: '#8187AD',
    '& > a': {
      color: '#8187AD'
    }
  },
  langButton: {
    fontSize: '1rem',
    width: '156px',
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914',
    textTransform: 'uppercase'
  },
  collapsedLangButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  expandedLangButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  '@media screen and (min-width: 600px)': {
    container: {
      padding: theme.spacing(6, 4, 0, 4)
    }
  }
}))

function Footer({ handleLoginOpen }) {
  const { t } = useTranslation()
  const classes = useStyles()

  // Get auth from redux state
  const auth = useSelector((state: AppState) => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)
  const firebase = useFirebase()
  const router = useRouter()
  const region = useRegion()

  const handleLogin = (e) => {
    e.preventDefault()
    handleLoginOpen()
  }

  const handleLogout = async (e) => {
    e.preventDefault()
    await firebase.logout()
    router.push(HOME_PATH(region))
  }

  return (
    <Grid container justifyContent='flex-start' className={classes.root}>
      <Grid container justifyContent='center' style={{ width: '98vw' }}>
        <Grid container item className={classes.container} spacing={6}>
          <Grid
            container
            item
            sm={4}
            lg={3}
            alignContent='flex-start'
            spacing={4}
            className={classes.gridItem}
          >
            <Grid
              container
              item
              xs={12}
              justifyContent='flex-start'
              className={classes.logoContainer}
            >
              <Image
                src='/images/logo.png'
                height={64}
                width={160}
                className={classes.logo}
                alt='Live Klassisk'
              />
            </Grid>
            <IconButton
              href='https://www.instagram.com/live_klassisk/'
              target='_blank'
              rel='noopener'
              className={classes.social}
            >
              <InstagramIcon fontSize='large' />
            </IconButton>
            <IconButton
              href='https://www.facebook.com/liveklassisk/'
              target='_blank'
              rel='noopener'
              className={classes.social}
            >
              <FacebookIcon fontSize='large' />
            </IconButton>
          </Grid>
          <Grid item sm={4} md={3} className={classes.gridItem}>
            <Box display={{ xs: 'none', sm: 'block' }}>
              <Typography variant='h5' className={classes.heading}>
                Live Klassisk
              </Typography>
              <MenuList>
                {!authExists ? (
                  <Link href='#'>
                    <a
                      onClick={handleLogin}
                      className={`${classes.menuItem} ${classes.newMenuItemGroup}`}
                    >
                      {t('Organizer login')}
                    </a>
                  </Link>
                ) : (
                  <div>
                    <Link href='#' passHref>
                      <a className={classes.menuItem} onClick={handleLogout}>
                        {t('Sign out')}
                      </a>
                    </Link>
                  </div>
                )}
              </MenuList>
            </Box>
          </Grid>
          <Grid item sm={4} md={3} className={classes.gridItem}>
            <Typography variant='h5' className={classes.heading}>
              {t('Contact')}
            </Typography>
            <Typography className={classes.address}>
              {t('+45 2241 4168')} <br />
              {t('info@liveklassisk.dk')} <br /> <br />
              {t('CVR 3395 2015')}
            </Typography>
          </Grid>
          <Grid container item justifyContent='center' xs={12}>
            <Typography variant='body1' className={classes.disabled}>
              Copyright © {new Date().getFullYear()} Live Klassisk •{' '}
              <Link href={PRIVACY_POLICY_PATH(region)}>
                {t('Privacy and cookie policy')}
              </Link>{' '}
              • Made with ♥ by{' '}
              <a
                href='https://kvalifik.dk'
                target='_blank'
                rel='noopener noreferrer'
              >
                Kvalifik
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Footer
