import { combineReducers } from 'redux'
import {
  firebaseReducer,
  FirebaseReducer,
  FirestoreReducer
} from 'react-redux-firebase'
import { firestoreReducer as firestore } from 'redux-firestore'
import { reducer as form } from 'redux-form'
import locationReducer from './location'

interface RootState {
  firebase: FirebaseReducer.Reducer
  firestore: FirestoreReducer.Reducer
}

const rootReducer = combineReducers({
  // Add sync reducers here
  firebase: firebaseReducer,
  firestore,
  form,
  location: locationReducer
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
