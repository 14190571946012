import '/styles/globals.css'
import React from 'react'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import { Provider as ReduxProvider } from 'react-redux'
import MomentUtils from '@date-io/moment'
import theme from '../themes/main'
import dynamic from 'next/dynamic'
import { ThemeProvider, CssBaseline } from '@material-ui/core'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore/memory'
import 'firebase/storage'
import 'firebase/analytics'
import {
  firebaseStagingConfig,
  reduxFirebase as rfConfig,
  firebase as fbConfig
} from '../utils/firebaseConfig'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { SnackbarProvider } from 'material-ui-snackbar-provider'
import Layout from '../components/layout/Layout'
import { FrontPagePopupProvider } from '../components/FrontPagePopupProvider'
import getEnv from '../utils/getEnv'
import createStore from '../store/index'
import 'moment/locale/da'
import 'moment/locale/sv'
import 'moment/locale/nb'
import moment from 'moment'
import { useTranslation, appWithTranslation } from 'next-i18next'

import Head from 'next/head'

// Initialize Firebase instance
if (!firebase.apps.length) {
  if (getEnv() === 'production') {
    firebase.initializeApp(fbConfig)
  } else {
    firebase.initializeApp(firebaseStagingConfig)
  }

  if (typeof window !== 'undefined') {
    firebase.analytics()
  }

  firebase.firestore()
}

const googleTagManagerId =
  getEnv() === 'production' ? 'GTM-M72QCQF' : 'GTM-WNFKX46'

const CrispWithNoSSR = dynamic(() => import('../components/Crisp'), {
  ssr: false
})

const store = createStore()

const App = (props) => {
  const { Component, pageProps } = props
  const { i18n } = useTranslation()
  const language = i18n.language
  moment.locale(language)

  return (
    <>
      <Head>
        <script
          id='mcjs'
          dangerouslySetInnerHTML={{
            __html: `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/42622a08a35e99daaec038795/0cdaca9ce6b076532506165c5.js");`
          }}
        ></script>
      </Head>
      <ReduxProvider store={store}>
        <script async src='https://js.stripe.com/v3/pricing-table.js' />
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`}
            height='0'
            width='0'
            style={{ display: 'none', visibility: 'hidden' }}
          ></iframe>
        </noscript>
        <SnackbarProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <MuiPickersUtilsProvider utils={MomentUtils} locale={language}>
              <ReactReduxFirebaseProvider
                firebase={firebase}
                config={rfConfig}
                dispatch={store.dispatch}
                createFirestoreInstance={createFirestoreInstance}
              >
                <FrontPagePopupProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </FrontPagePopupProvider>
                <CrispWithNoSSR />
              </ReactReduxFirebaseProvider>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </SnackbarProvider>
      </ReduxProvider>
    </>
  )
}

export default appWithTranslation(App)
