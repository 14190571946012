import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Popover, Paper, IconButton } from '@material-ui/core'
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons'
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import { makeStyles } from '@material-ui/core/styles'
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: '#8187AD !important'
  },
  menuButton: {
    minWidth: '40px',
    padding: theme.spacing(1, 0),
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914'
  },
  collapsedMenuButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  expandedMenuButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  popoverMenu: {
    marginTop: theme.spacing(2)
  },
  expandedMenu: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    minWidth: '260px',
    padding: theme.spacing(3, 3, 1.5, 3),
    textTransform: 'uppercase'
  },
  expandedMenuItem: {
    display: 'flex',
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 1.5, 0),
    color: 'white'
  },
  newMenuItemGroup: {
    marginTop: theme.spacing(3)
  }
}))

interface HamburgerMenuProps {
  handleLoginOpen(): void
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ handleLoginOpen }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const firebase = useFirebase()
  const router = useRouter()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'hamburger'
  })

  // Get auth from redux state
  const auth = useSelector((state: any) => state.firebase.auth)
  const authExists = isLoaded(auth) && !isEmpty(auth)

  const handleClose = () => popupState.close()

  const handleLogin = (e) => {
    e.preventDefault()
    popupState.close()
    handleLoginOpen()
  }

  const handleLogout = async (e) => {
    e.preventDefault()
    popupState.close()
    await firebase.logout()
    router.push('/')
  }

  return (
    <>
      <IconButton
        className={`${classes.menuButton} ${
          popupState.isOpen
            ? classes.expandedMenuButton
            : classes.collapsedMenuButton
        }`}
        {...bindTrigger(popupState)}
        disableRipple
      >
        {popupState.isOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        elevation={0}
        className={classes.popoverMenu}
      >
        <Paper className={classes.expandedMenu}>
          <Link href='/'>
            <a onClick={handleClose} className={classes.expandedMenuItem}>
              {t('Concerts')}
            </a>
          </Link>
          <Link href='/'>
            <a onClick={handleClose} className={classes.expandedMenuItem}>
              {t('News')}
            </a>
          </Link>
          <Link href='/'>
            <a onClick={handleClose} className={classes.expandedMenuItem}>
              {t('Festivals')}
            </a>
          </Link>
          <Link href='/'>
            <a onClick={handleClose} className={classes.expandedMenuItem}>
              {t('Concert Series')}
            </a>
          </Link>
          <Link href='/'>
            <a onClick={handleClose} className={classes.expandedMenuItem}>
              {t('Ensembles')}
            </a>
          </Link>
          <Link href='/'>
            <a onClick={handleClose} className={classes.expandedMenuItem}>
              {t('Venues')}
            </a>
          </Link>
          <Link href='/'>
            <a
              onClick={handleClose}
              className={`${classes.expandedMenuItem} ${classes.newMenuItemGroup}`}
            >
              {t('About us')}
            </a>
          </Link>
          <Link href='/'>
            <a onClick={handleClose} className={classes.expandedMenuItem}>
              {t('Contact')}
            </a>
          </Link>
          <Link href='/'>
            <a
              onClick={handleClose}
              className={`${classes.expandedMenuItem} ${classes.newMenuItemGroup}`}
            >
              {t('Partners & sponsors')}
            </a>
          </Link>
          <Link href='/'>
            <a onClick={handleClose} className={classes.expandedMenuItem}>
              {t('Prices')}
            </a>
          </Link>
          {!authExists ? (
            <Link href='#'>
              <a
                onClick={handleLogin}
                className={`${classes.expandedMenuItem} ${classes.newMenuItemGroup}`}
              >
                {t('Organizer login')}
              </a>
            </Link>
          ) : (
            <a
              onClick={handleLogout}
              className={classes.expandedMenuItem}
              style={{ cursor: 'pointer' }}
            >
              {t('Sign out')}
            </a>
          )}
        </Paper>
      </Popover>
    </>
  )
}

export default HamburgerMenu
