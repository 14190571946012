import React from 'react'
import Link from 'next/link'
import { PRIVACY_POLICY_PATH } from '../constants/paths'
import CookieConsent from 'react-cookie-consent'
import { useTranslation } from 'next-i18next'
import { useRegion } from '../hooks/useRegion'

interface CookieBannerProps {}

const CookieBanner: React.FC<CookieBannerProps> = ({}) => {
  const { t } = useTranslation()
  const root = {
    alignItems: 'center',
    backgroundColor: '#202A70',
    color: 'white',
    fontSize: '1rem',
    padding: '0 12px',
    boxShadow: '0 -3px 11px #08135920',
    '& > a': {
      color: 'white',
      fontWeight: 'bold',
      '&:visited': {
        color: 'white'
      }
    }
  }
  const button = {
    backgroundColor: 'white',
    color: '#081359',
    fontFamily: 'futura',
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: '8px 32px',
    margin: '12px auto',
    borderRadius: '8px'
  }

  const link = {
    color: 'white'
  }

  const region = useRegion()

  return (
    <CookieConsent
      location='bottom'
      style={root}
      buttonStyle={button}
      buttonText={t('I understand')}
    >
      {t('We use cookies on our website')}.<br />
      {t(
        'You can manage this via your browser setting at any time. To learn more about how we use the cookies, please see our'
      )}{' '}
      <Link href={PRIVACY_POLICY_PATH(region)}>
        <a style={link}>{t('cookies policy')}.</a>
      </Link>
    </CookieConsent>
  )
}

export default CookieBanner
