import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useFirebase } from 'react-redux-firebase'
import {
  Grid,
  InputLabel,
  TextField,
  CircularProgress,
  Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'next-i18next'
import axios from 'axios'
import getEnv from '../utils/getEnv'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: '100%',
    width: '100%',
    color: theme.palette.primary.main,
    '& .MuiTypography-h2': {
      fontWeight: 600,
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8187AD',
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F5F6F8',
      '&.Mui-disabled': {
        backgroundColor: '#FFF'
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    },
    '& .MuiInputBase-root': {
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiOutlinedInput-input': {
      color: theme.palette.primary.main
    },
    '& .MuiAutocomplete-root': {
      color: theme.palette.primary.main
    }
  },
  form: {
    maxWidth: '250px',
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    textAlign: 'center'
  },
  inputLabel: {
    paddingLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.primary.main,
    textAlign: 'left'
  },
  input: {
    backgroundColor: '#F5F6F8',
    color: theme.palette.primary.main,
    '&::placeholder': {
      color: '#8187AD'
    }
  },
  inputOutline: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  inputBase: {
    padding: theme.spacing(1.5, 1.5)
  },
  loginButton: {
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914',
    padding: theme.spacing(1, 6, 1, 6),
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover, &:active, &:hover:disabled, &:disabled': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    right: '0',
    marginTop: -12,
    marginRight: 12
  },
  errorMessage: {
    fontSize: '1rem',
    height: '40px',
    width: '100%',
    textAlign: 'center',
    color: 'red',
    marginBottom: theme.spacing(2)
  }
}))

const errorMsg = (code, t) => {
  switch (code) {
    case undefined:
      return undefined
    case 'auth/user-not-found':
      return t('Incorrect username.')
    case 'auth/wrong-password':
      return t('Incorrect password.')
    default:
      return t('An error occured.')
  }
}

const url =
  getEnv() === 'production'
    ? 'https://europe-west1-liveklassisk.cloudfunctions.net/customAuth'
    : 'https://europe-west1-liveklassisk-staging.cloudfunctions.net/customAuth'

const LoginForm = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const firebase = useFirebase()

  const [error, setError] = useState(undefined)
  const errorMessage = errorMsg(error, t)

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    axios
      .post(url, {
        data: {
          username: values.username.toLowerCase(),
          password: values.password
        }
      })
      .then((resp) => {
        if (resp.data.result.token) {
          firebase.auth().signInWithCustomToken(resp.data.result.token)
        } else {
          console.log(resp)
          setSubmitting(false)
          setError(resp.data.result.code)
        }
      })
      .catch((err) => {
        setSubmitting(false)
        console.log(err)
        setError(err.code)
      })
  }

  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        username: Yup.string().required(t('Please enter your username')),
        password: Yup.string().required(t('Please enter your password'))
      })}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('Username')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t('Enter your username')}
                name='username'
                type='text'
                autoComplete='username'
                error={!!(errors.username && touched.username)}
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.username && touched.username && errors.username
                }
                InputProps={{
                  className: classes.input,
                  classes: {
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('Password')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t('Enter your password')}
                type='password'
                name='password'
                autoComplete='current-password'
                error={!!(errors.password && touched.password)}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors.password && touched.password && errors.password
                }
                InputProps={{
                  className: classes.input,
                  classes: {
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.submit}>
                <Button
                  type='submit'
                  fullWidth
                  className={classes.loginButton}
                  disabled={isSubmitting}
                >
                  {t('Log in')}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.errorMessage}>
              {errorMessage}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
