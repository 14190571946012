import MeiliSearch, { Documents } from 'meilisearch'
import getEnv from './getEnv'

const meiliConfig =
  getEnv() === 'production'
    ? {
        host: 'https://meilisearch.liveklassisk.com',
        apiKey:
          '3b408d017a797b1de83f3dd00cd1f4acc05f2b0c1898c8f3834a564f25db7f3a'
      }
    : {
        host: 'https://meilisearch.staging.liveklassisk.com',
        apiKey:
          '8f96d69d9f54056bf9c2639a3008411e7ed28c0c09295ec4de9b24230161ef52'
      }

// Available client-side.
export const meiliSearchPublicClient = new MeiliSearch(meiliConfig)

// Only available server-side, because environment var is not prefixed with NEXT_PUBLIC.
export const meiliSearchAdminClient = new MeiliSearch({
  ...meiliConfig,
  apiKey: process.env.MEILISEARCH_ADMIN_API_KEY
})

export type Indexed<T> = T & { objectID: string }

export const getAllDocuments = async <T>(
  indexId: string
): Promise<Documents<T>> => {
  const limit = 100000
  const index = meiliSearchAdminClient.index(indexId)
  const docs = await index.getDocuments<T>({ limit })

  return docs.results
}

export const getDocumentById = async <T>(
  indexId: string,
  objectID: string
): Promise<T | null> => {
  const index = meiliSearchAdminClient.index(indexId)
  try {
    const doc = await index.getDocument<T>(objectID)
    return doc
  } catch (error) {
    return null
  }
}
