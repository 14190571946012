import React from 'react'
import { DAData, NOData, SEData } from '../constants/popupData'
import { useRegion } from '../hooks/useRegion'

interface ContextState {
  open: any
  setOpen: any
  data: any
}

export const PopupContext = React.createContext({} as ContextState)

const getData = (region: string) => {
  switch (region) {
    case 'se':
      return SEData
    case 'no':
      return NOData
    default:
      return DAData
  }
}

export const FrontPagePopupProvider = (props) => {
  const region = useRegion()
  const data = getData(region)
  const [open, setOpen] = React.useState(data.visible)
  return (
    <PopupContext.Provider value={{ open, setOpen, data }}>
      {props.children}
    </PopupContext.Provider>
  )
}
