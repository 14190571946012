import React, { useState } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useFirebase } from 'react-redux-firebase'
import {
  Grid,
  InputLabel,
  TextField,
  CircularProgress,
  Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'next-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: '100%',
    width: '100%',
    color: theme.palette.primary.main,
    '& .MuiTypography-h2': {
      fontWeight: 600,
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8187AD',
      color: theme.palette.primary.main
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#F5F6F8',
      '&.Mui-disabled': {
        backgroundColor: '#FFF'
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.main
    },
    '& .MuiInputBase-root': {
      color: `${theme.palette.primary.main} !important`
    },
    '& .MuiOutlinedInput-input': {
      color: theme.palette.primary.main
    },
    '& .MuiAutocomplete-root': {
      color: theme.palette.primary.main
    }
  },
  form: {
    maxWidth: '250px',
    width: '100%',
    color: theme.palette.primary.main
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    textAlign: 'center'
  },
  inputLabel: {
    paddingLeft: theme.spacing(1.5),
    marginBottom: theme.spacing(0.5),
    color: theme.palette.primary.main,
    textAlign: 'left'
  },
  input: {
    backgroundColor: '#F5F6F8',
    color: theme.palette.primary.main,
    '&::placeholder': {
      color: '#8187AD'
    }
  },
  inputOutline: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  inputBase: {
    padding: theme.spacing(1.5, 1.5)
  },
  submitButton: {
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914',
    padding: theme.spacing(1, 6, 1, 6),
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover, &:active, &:hover:disabled, &:disabled': {
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    }
  },
  buttonProgress: {
    color: 'white',
    position: 'absolute',
    top: '50%',
    right: '0',
    marginTop: -12,
    marginRight: 12
  },
  message: {
    fontSize: '1rem',
    height: '40px',
    width: '100%',
    textAlign: 'center',
    color: 'red',
    marginBottom: theme.spacing(2)
  },
  errorMessage: {
    color: 'red'
  },
  successMessage: {
    color: 'green'
  }
}))

const errorMsg = (code, t) => {
  switch (code) {
    case undefined:
      return undefined
    case 'auth/invalid-email':
      return t('Invalid e-mail.')
    case 'auth/user-not-found':
      return t('We have no account with that e-mail.')
    default:
      return t('An error occured.')
  }
}

const ForgotForm = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const firebase = useFirebase()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(undefined)
  const errorMessage = success
    ? t('Password reset e-mail sent. Please check your inbox.')
    : errorMsg(error, t)

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true)
    firebase
      .resetPassword(values.email)
      .then(() => {
        setSubmitting(false)
        setSuccess(true)
      })
      .catch((err) => {
        setSubmitting(false)
        setSuccess(false)
        setError(err.code)
      })
  }

  return (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email(t('Please enter a valid email'))
          .required(t('Please enter your email'))
      })}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className={classes.inputLabel}>
                {t('E-mail address')}
              </InputLabel>
              <TextField
                variant='outlined'
                placeholder={t('Enter your e-mail')}
                name='email'
                autoComplete='email'
                error={!!(errors.email && touched.email)}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={errors.email && touched.email && errors.email}
                InputProps={{
                  className: classes.input,
                  classes: {
                    notchedOutline: classes.inputOutline
                  }
                }}
                inputProps={{
                  className: classes.inputBase
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.submit}>
                <Button
                  type='submit'
                  fullWidth
                  className={classes.submitButton}
                  disabled={isSubmitting}
                >
                  {t('Send reset link')}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              className={`${classes.message}, ${
                success ? classes.successMessage : classes.errorMessage
              }`}
            >
              {errorMessage}
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}

export default ForgotForm
