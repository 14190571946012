export const getRegionFromWindowLocation = (): string => {
  if (typeof window === 'undefined') return ''
  const region = window.location.pathname.substring(1, 3)
  return region
}

export type Region = 'dk' | 'se' | 'no'
export type Locale = 'da' | 'sv' | 'nb' | 'en'

export const getLanguageLocalName = (locale: Locale): string => {
  switch (locale) {
    case 'da':
      return 'Dansk'
    case 'nb':
      return 'Norsk'
    case 'sv':
      return 'Svenska'
    default:
      return 'English'
  }
}

export const localizedCountryName = (region: string): string => {
  switch (region) {
    case 'dk':
      return 'Denmark'
    case 'se':
      return 'Sweden'
    case 'no':
      return 'Norway'
    case 'en':
      return 'Scandinavia'
    default:
      return 'Denmark'
  }
}

export const localeToLanguageKey = (locale: Locale): string => {
  switch (locale) {
    case 'da':
      return 'Danish'
    case 'sv':
      return 'Swedish'
    case 'nb':
      return 'Norwegian'
    case 'en':
      return 'English'
  }
}

export interface LanguageSelectProps {
  id: string
  popupState: any
}

export const regionToLocale = (region: string): Locale => {
  switch (region) {
    case 'dk':
      return 'da'
    case 'se':
      return 'sv'
    case 'no':
      return 'nb'
    default:
      return 'en'
  }
}
