import React from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Popover, Paper, IconButton } from '@material-ui/core'
import { Menu as MenuIcon, Close as CloseIcon } from '@material-ui/icons'
import { useFirebase } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  usePopupState,
  bindTrigger,
  bindPopover
} from 'material-ui-popup-state/hooks'
import {
  HOME_PATH,
  BACKSTAGE_CONCERT_LIST_PATH,
  BACKSTAGE_USER_LIST_PATH,
  BACKSTAGE_VENUE_LIST_PATH,
  ACCOUNT_PATH,
  BACKSTAGE_ENSEMBLE_LIST_PATH,
  BACKSTAGE_FESTIVAL_LIST_PATH,
  BACKSTAGE_CONCERTSERIES_LIST_PATH,
  BACKSTAGE_ADGROUPS_LIST_PATH,
  BACKSTAGE_NEWS_LIST_PATH,
  BACKSTAGE_PRICES_PATH,
  SPONSORS_PATH,
  ABOUT_PATH,
  CONTACT_PATH
} from '../../constants/paths'
import { useTranslation } from 'next-i18next'
import { useRegion } from '../../hooks/useRegion'

const useStyles = makeStyles((theme) => ({
  disabled: {
    color: '#8187AD !important'
  },
  menuButton: {
    minWidth: '40px',
    padding: theme.spacing(1, 0),
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914'
  },
  collapsedMenuButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  expandedMenuButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  popoverMenu: {
    marginTop: theme.spacing(2)
  },
  expandedMenu: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '8px',
    minWidth: '260px',
    padding: theme.spacing(3, 3, 1.5, 3),
    textTransform: 'uppercase'
  },
  expandedMenuItem: {
    display: 'flex',
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 1.5, 0),
    color: 'white'
  },
  newMenuItemGroup: {
    marginTop: theme.spacing(3)
  }
}))

interface BackstageHamburgerMenuProps {}

const BackstageHamburgerMenu: React.FC<BackstageHamburgerMenuProps> = ({}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const firebase = useFirebase()
  const router = useRouter()
  const user = useSelector((state: any) => state.firebase.profile)
  const region = useRegion()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'backstage-hamburger'
  })

  const handleClose = () => popupState.close()

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    popupState.close()
    await firebase.logout()
    router.push(HOME_PATH(region))
  }

  return (
    <>
      <IconButton
        className={`${classes.menuButton} ${
          popupState.isOpen
            ? classes.expandedMenuButton
            : classes.collapsedMenuButton
        }`}
        {...bindTrigger(popupState)}
        disableRipple
      >
        {popupState.isOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        elevation={0}
        className={classes.popoverMenu}
      >
        <Paper className={classes.expandedMenu}>
          <Link href={ACCOUNT_PATH(region)} passHref>
            <a className={classes.expandedMenuItem} onClick={handleClose}>
              {t('My account')}
            </a>
          </Link>
          <Link href={BACKSTAGE_PRICES_PATH(region)} passHref>
            <a className={classes.expandedMenuItem} onClick={handleClose}>
              {t('Subscriptions and prices')}
            </a>
          </Link>
          <Link href={BACKSTAGE_CONCERT_LIST_PATH(region)} passHref>
            <a className={classes.expandedMenuItem} onClick={handleClose}>
              {user.isSuperAdmin ? t('Concerts') : t('My concerts')}
            </a>
          </Link>
          <Link href={BACKSTAGE_FESTIVAL_LIST_PATH(region)} passHref>
            <a className={classes.expandedMenuItem} onClick={handleClose}>
              {t('Festivals')}
            </a>
          </Link>
          {user.isSuperAdmin && (
            <>
              <Link href={BACKSTAGE_CONCERTSERIES_LIST_PATH(region)} passHref>
                <a className={classes.expandedMenuItem} onClick={handleClose}>
                  {t('Concert Series')}
                </a>
              </Link>
              <Link href={BACKSTAGE_ENSEMBLE_LIST_PATH(region)} passHref>
                <a className={classes.expandedMenuItem} onClick={handleClose}>
                  {t('Ensembles')}
                </a>
              </Link>
              <Link href={BACKSTAGE_VENUE_LIST_PATH(region)} passHref>
                <a className={classes.expandedMenuItem} onClick={handleClose}>
                  {t('Venues')}
                </a>
              </Link>
            </>
          )}
          <Link href={BACKSTAGE_NEWS_LIST_PATH(region)} passHref>
            <a className={classes.expandedMenuItem} onClick={handleClose}>
              {t('News')}
            </a>
          </Link>
          {user.isSuperAdmin && (
            <>
              <Link href={BACKSTAGE_USER_LIST_PATH(region)} passHref>
                <a className={classes.expandedMenuItem} onClick={handleClose}>
                  {t('Users')}
                </a>
              </Link>
              <Link href={BACKSTAGE_ADGROUPS_LIST_PATH(region)} passHref>
                <a className={classes.expandedMenuItem} onClick={handleClose}>
                  {t('Advertisements')}
                </a>
              </Link>
            </>
          )}

          <Link href={ABOUT_PATH(region)} passHref>
            <a
              className={`${classes.expandedMenuItem} ${classes.newMenuItemGroup}`}
              onClick={handleClose}
            >
              {t('About us')}
            </a>
          </Link>
          <Link href={SPONSORS_PATH(region)} passHref>
            <a className={classes.expandedMenuItem} onClick={handleClose}>
              {t('Partners & sponsors')}
            </a>
          </Link>
          <Link href={CONTACT_PATH(region)} passHref>
            <a className={classes.expandedMenuItem} onClick={handleClose}>
              {t('Contact')}
            </a>
          </Link>

          <Link href={HOME_PATH(region)} passHref>
            <a
              className={`${classes.expandedMenuItem} ${classes.newMenuItemGroup}`}
              onClick={handleClose}
            >
              {t('Back to Live Klassisk')}
            </a>
          </Link>
          <Link href='#' passHref>
            <a className={classes.expandedMenuItem} onClick={handleLogout}>
              {t('Sign out')}
            </a>
          </Link>
        </Paper>
      </Popover>
    </>
  )
}

export default BackstageHamburgerMenu
