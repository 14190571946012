import React from 'react'
import Link from 'next/link'
import { AppBar, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Image from 'next/image'
import BackstageHamburgerMenu from './BackstageHamburgerMenu'
import { HOME_PATH } from '../../constants/paths'
import { useRegion } from '../../hooks/useRegion'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'white'
  },
  disabled: {
    color: '#8187AD !important'
  },
  appBar: {
    zIndex: 1300,
    height: '80px',
    padding: theme.spacing(0, 5),
    boxShadow: '0px 3px 11px #08135914',
    backgroundColor: 'white'
  },
  logoContainer: {
    height: '80px',
    padding: theme.spacing(1)
  },
  logo: {
    width: '100%',
    objectFit: 'contain',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  backText: {
    color: '#8187AD',
    '&:hover': {
      color: '#8187AD'
    }
  },
  menuButton: {
    minWidth: '40px',
    padding: theme.spacing(1, 0),
    borderRadius: '8px',
    boxShadow: '0px 3px 11px #08135914'
  },
  collapsedMenuButton: {
    backgroundColor: 'white',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  expandedMenuButton: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  }
}))

interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = ({}) => {
  const classes = useStyles()
  const region = useRegion()

  return (
    <div className={classes.root}>
      <AppBar position='fixed' className={classes.appBar} elevation={0}>
        <Grid container justifyContent='space-between'>
          <Grid item xs={3} className={classes.logoContainer}>
            <Link href={HOME_PATH(region)} passHref>
              <a>
                <Image
                  src='/images/logo.png'
                  height={64}
                  width={160}
                  className={classes.logo}
                  data-test='brand'
                  alt='Live Klassisk logo'
                />
              </a>
            </Link>
          </Grid>
          <Grid container item xs alignItems='center' justifyContent='flex-end'>
            <BackstageHamburgerMenu />
          </Grid>
        </Grid>
      </AppBar>
    </div>
  )
}

export default Navbar
